// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleWebClientId: '286425454623-3leqmjh55paiq73ap5rjfcu41c7ap13g.apps.googleusercontent.com',
  firebase: {
    apiKey: "AIzaSyCqtVoSEksukJjYV5FWak86Ej2xIir7Wyg",
    authDomain: "app-namiptc.firebaseapp.com",
    databaseURL: "https://app-namiptc-default-rtdb.firebaseio.com",
    projectId: "app-namiptc",
    storageBucket: "app-namiptc.appspot.com",
    messagingSenderId: "751323304226",
    appId: "1:751323304226:web:f0b14f27afb8ac8cf7957b",
    measurementId: "G-R0YJ7J4ZC3"

  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
